<template>
  <div class="search">
		<div>
			<div  class="headForm">
				<el-form :model="queryForm"  :inline="true" ref="queryForm" class="demo-form-inline">
					<el-row :gutter="24">
						<el-col :span="6" align="left">
							<div class="grid-content bg-purple">
								<el-form-item prop="account" label="平台账号:">
									<el-input v-model="queryForm.account" placeholder="请输入查询账号"></el-input>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="6" align="left" >
							<div class="grid-content bg-purple">
								<el-form-item prop= 'name' label="租户名称:" >
									<el-input v-model="queryForm.name" placeholder="请输入姓名查询"></el-input>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="6" align="left">
							<div class="grid-content bg-purple">
								<el-form-item prop="flag" label="用户状态:">
									<!-- <el-input v-model="queryForm.flag" placeholder="请输入姓名查询"></el-input> -->
									<el-select v-model="queryForm.flag" placeholder="请选择">
										<el-option label="全部状态" :value="null" />
                    <el-option label="已启用" :value="0" />
                    <el-option label="已禁用" :value="1" />
										<!-- <el-option
										v-for="item in options"
										:key="item.value"
										:label="item.name"
										:value="parseInt(item.datavalue)">
										</el-option> -->
									</el-select>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="6" align="left">
							<div>
								<el-form-item class="buttonbox">
									<el-button type="primary" icon="el-icon-search" @click="loadTable">查询</el-button>
									<el-button type="primary" icon="el-icon-refresh-right" @click="resetForm('queryForm')" >重置</el-button>
								</el-form-item>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col align="left">
							<el-button type="primary" icon="el-icon-plus" @click="addTenant">添加租户</el-button>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<div>
				<el-table :data="tableList" :header-cell-style="tableHeader" :cell-style="tableCell" border fit v-loading="tableLoading"
					>
					<el-table-column width="80" type="index" label="编号" :show-overflow-tooltip="true" align="center"></el-table-column>
					<el-table-column prop="account" label="平台账号" :show-overflow-tooltip="true" align="center"></el-table-column>
					<el-table-column prop="tname" label="租户名称" :show-overflow-tooltip="true" align="center"></el-table-column>
					<el-table-column prop="code" label="社会统一信用代码/组织机构代码" width="300" :show-overflow-tooltip="true" align="center" />
					<el-table-column prop="name" label="租户联系人" :show-overflow-tooltip="true" align="center" />
					<el-table-column prop="mobile" label="联系方式" :show-overflow-tooltip="true" align="center" />
					<el-table-column sortable prop="flagName" label="状态" align="center" />
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-link type="primary" :underline="false" size="medium" style="margin: 0 5px" @click="updateUser(scope.row)">修改</el-link>
							<el-link type="primary" :underline="false" size="medium" style="margin: 0 5px" @click="updatePassword(scope.row)">密码</el-link>
							<el-link type="primary" :underline="false" size="medium" style="margin: 0 5px" @click="permissiondis(scope.row)">权限</el-link>
							<el-link type="warning" :underline="false" size="medium" style="margin: 0 0 0 5px" v-if="scope.row.flag==0"
							@click="submitButtonValue = '停用';stateDialogType = 'warning';stateDialogMsg='确认停用该用户吗？';openUpdateState(scope.row,1)">停用</el-link>
							<el-link type="success" :underline="false" size="medium" style="margin: 0 0 0 5px" v-else-if="scope.row.flag==1"
							@click="submitButtonValue = '启用';stateDialogType = 'success';stateDialogMsg='确认启用该用户吗？';openUpdateState(scope.row,0)">启用</el-link>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination id="page" background
					:page-sizes="[10, 15, 20]"
					@size-change="sizeChange"
					@current-change="currentChange"
					@prev-click="currentChange"
					@next-click="currentChange"
					:page-size="queryForm.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="pagingTotal">
				</el-pagination>
			</div>
		</div>
		<div>
			<!-- 添加和更新 -->
			<el-dialog
			:title="dialogHeadTitle"
			:visible.sync="addOrUpdateDialogVisible"
			width="30%"
			@closed="afteraddOrUpdateDialogClose">
			<el-form :model="addOrUpdateForm" ref="addOrUpdateForm" :rules="addOrUpdateFormFules" label-width="100px" class="addOrUpdateDialogForm">
			<el-form-item label="账号：" prop="loginName" class="addOrUpdateDialogFormItem" label-width="100px">
				<el-input v-model="addOrUpdateForm.loginName"></el-input>
			</el-form-item>
			<el-form-item label="用户名：" prop="loginName" class="addOrUpdateDialogFormItem">
				<el-input v-model="addOrUpdateForm.loginName"></el-input>
			</el-form-item>
			<el-form-item label="联系方式：" prop="loginName" class="addOrUpdateDialogFormItem">
				<el-input v-model="addOrUpdateForm.loginName"></el-input>
			</el-form-item>
			<el-form-item label="登录密码：" prop="loginName" class="addOrUpdateDialogFormItem">
				<el-input v-model="addOrUpdateForm.loginName"></el-input>
			</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addOrUpdateDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitAddOrUpdateForm()">{{submitButtonValue}}</el-button>
			</span>
			</el-dialog>

			<!-- 修改密码 -->
			<el-dialog
			title="重置密码"
			:visible.sync="updatePasswordDialogVisible"
			width="30%"
			@closed="afterUpdatePasswordDialogClose">
			<el-form :model="updatePasswordForm" ref="updatePasswordForm" :rules="updatePasswordFormFules" label-width="100px" class="addOrUpdateDialogForm">
			<el-form-item label="账号：" prop="account" class="addOrUpdateDialogFormItem" label-width="100px">
				<el-input :disabled="true" v-model="updatePasswordForm.account"></el-input>
			</el-form-item>
			<el-form-item label="登录密码" prop="newPwd" class="addOrUpdateDialogFormItem">
				<el-input v-model="updatePasswordForm.newPwd" type="password"></el-input>
			</el-form-item>
			<el-form-item label="确认密码：" prop="verifyPassword" class="addOrUpdateDialogFormItem">
				<el-input v-model="updatePasswordForm.verifyPassword" type="password"></el-input>
			</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updatePasswordDialogVisible = false;resetForm('updatePasswordForm')">取 消</el-button>
				<el-button type="primary" @click="submitUpdatePasswordForm()">重 置</el-button>
			</span>
			</el-dialog>
			<!-- 启用禁用提示 -->
			<el-dialog
				title="提示"
				:visible.sync="updateStateDialogVisible"
				width="30%">
				<span style="text-align: center;display: block;">{{stateDialogMsg}}</span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="updateStateDialogVisible = false">取 消</el-button>
					<el-button :type="stateDialogType" @click="updateUserFlag">{{submitButtonValue}}</el-button>
				</span>
			</el-dialog>
			<div>
			<el-drawer v-show="true" :visible.sync="dialogVisiblepermission">
				<sysdistribution :user="userId" :tenant="tenant" :syncPermission="true"></sysdistribution>
				<span slot="footer" class="dialog-footer">
					<el-button type="success" @click="cancelpermission()">取消</el-button>
				</span>
			</el-drawer>
		</div>
		</div>
	</div>
</template>
<script>
//  SysUserList  getMyUserPermission

import sysdistribution from "../user/sysdistribution";
export default {
    data() {
	var validatePass = (rule, value, callback) => {
        if (value == '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.updatePasswordForm.verifyPassword !== '') {
            this.$refs.updatePasswordForm.validateField('verifyPassword');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value == '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.updatePasswordForm.newPwd) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
		  tableLoading: true,
		  queryForm: {
			  account: '',
			  name: '',
			  flag: null,
			  tenantId: '',
			  sessionId: '',
			  pageNo: 1,
			  pageSize: 10
		  },
		  // 查询form 状态选择框
		  options:[],
		  // 表格数据
		  tableList: [],
		  pagingTotal: 0,
		  // 添加或修改对话框
		  addOrUpdateDialogVisible: false,
		  //DialogHeadTitle
		  dialogHeadTitle: '',
		  submitButtonValue: '',
		  // 添加或修改form
		  addOrUpdateForm: {
			loginName: ''
		  },
		  // 添加必选项
		  addOrUpdateFormFules: {
			  loginName: [{required: true, message: '用户名不能为空！', trigger: 'blur'}]
		  },

		  // 更新密码模块
		  updatePasswordDialogVisible: false,
		  updatePasswordForm: {
			  user: '',
				account: '',
				newPwd: '',
				verifyPassword: '',
				tenant: '',
		  },
		  updatePasswordFormFules: {
			  	newPwd: [
					{ validator: validatePass, trigger: 'blur' }
				],
				verifyPassword: [
					{ validator: validatePass2, trigger: 'blur' }
				]},
		  updateStateDialogVisible: false,
		  updateUserFlagForm:{
			  id: '',
			  flag: null,
				tenant: ''
		  },
          stateDialogType: '',
		  stateDialogMsg:'',
		  // 权限 
		  dialogVisiblepermission: false,
		  userId: "",
		  tenant: "",
      }
    },
    // 实例创建完成后调用
    async created(){
	// 	let flag = this;
		this.queryForm.sessionId = this.$getCookie("sessionId");
		 // 获取数组字典
    // await this.api.platformApi.findDictionariesItemList({"code": process.env.VUE_APP_CODE_USER_FLAG}).then(res => {
		// 	res = res.data;
		// 	console.log(res);
		// 	if(res.code == 200){
		// 		this.options = res.data;
		// 	}
	  // })
      this.loadTable();
    },
    methods: {
		// 加载列表
		async loadTable(){
			this.tableLoading = true;
			await this.api.platformApi.getTenantAndUserList(this.queryForm).then(res => {
				res = res.data
				this.tableLoading = false;
				if(res.code == 200){
					this.tableList = res.data.records; 
					this.pagingTotal = res.data.total;
				}
			})
		},
		addTenant(){
			this.$router.push({path: `/addOrUpdateTenant/${0}`});
		},
		// 关闭添加对话框
		addOrUpdateDialogClose(done) {
			done();
		},
		// 关闭
		afteraddOrUpdateDialogClose(){
			this.resetForm('addOrUpdateForm');
		},
		// 提交添加或修改表单
		submitAddOrUpdateForm(){
			//提交表单
			this.addOrUpdateDialogVisible = false;
		},
		// 修改用户
		updateUser(row){
			// this.dialogHeadTitle = '修改用户'
			// this.submitButtonValue = '修 改'
			// this.addOrUpdateDialogVisible = true;
			this.$router.push({path:`/addOrUpdateTenant/${1}`,query: {id: row.id,tid: row.tid,code: row.code}});
		},
		// 修改密码
		updatePassword(row){
			this.updatePasswordForm.account = row.account;
			this.updatePasswordForm.user = row.id;
			this.updatePasswordForm.tenant = row.tid;
			this.updatePasswordDialogVisible = true;
		},
		// 提交重置密码表单
		async submitUpdatePasswordForm(){
			this.$refs['updatePasswordForm'].validate((valid) => {
				if (valid) {
					// 提交重置密码表单
					let flag = this;
					this.api.platformApi.resetPwdNotVerify(this.updatePasswordForm).then(res => {
						res = res.data
						if(res.code == 200){
							flag.$message({
								message: '重置成功！',
								type: 'success'
							});
						}
					})
					this.updatePasswordDialogVisible = false;
				} else {
				}
			});
			
		},
		// 关闭重置密码对话框的回调
		afterUpdatePasswordDialogClose(){
			this.resetForm('updatePasswordForm');
		},
		// 打开启用对话框
		openUpdateState(row,flag){
			this.updateUserFlagForm.id = row.id;
			this.updateUserFlagForm.tenant = row.tid;
			this.updateUserFlagForm.flag = flag;
			this.updateStateDialogVisible = true;
		},
		// 修改用户标识
		async updateUserFlag(){
			await this.api.platformApi.updateTenantUserFlag(this.updateUserFlagForm).then(res => {
				res = res.data
				console.log(res)
				if(res.code == 200){
					this.$message({
						message: '操作成功！',
						type: 'success'
					});
				}else{
					 this.$message.error(res.msg);
				}
			})
			this.updateStateDialogVisible = false;
			this.loadTable();
		},
		permissiondis(row) {
			this.userId = row.id;
			this.tenant = row.tid;
			console.log(row);
			this.dialogVisiblepermission = true;
		},
		// 分页改变时触发
		currentChange(page){
			this.queryForm.pageNo = page;
			this.loadTable();
		},
		sizeChange(size){
			this.queryForm.pageSize = size;
			this.loadTable();
      	},
		// 重置表单
		resetForm(form){
			 this.$refs[form].resetFields();
		},
      	tableHeader(column) {
			return "height:36px;background-color:#fafafa;color:#333333;padding:0";
		},
		tableCell(column) {
			return "height:40px;background-color:#fafafa;color:#000000;padding:0";
		},
    },
	components: {
		sysdistribution,
	},
	
    
}
</script>

<style scoped>

.search {
	background-color: white;
	padding: 15px 20px 100px 20px;
	overflow: hidden;
}

.headForm{
	display: block;
	margin-bottom: 20px;
}
#page {
  float: right;
  margin-top: 15px;
}

.quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

>>> .el-drawer.rtl {
  overflow: scroll;
}

#packUtil{
	display: none;
}
>>> .el-form-item__label{
	width: 80px;
}



>>>.el-dialog__body {
	border-top: 1px solid rgba(228, 228, 228, 1);
	border-bottom: 1px solid rgb(228, 228, 228, 1);
	padding-left: 0;
	padding-right: 0;
}
>>> .el-dialog{
	text-align: initial;
}

.addOrUpdateDialogForm{
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
}
.addOrUpdateDialogFormItem{
	width: 80%;
}
</style>


